import React from 'react';
import ImageMapper from 'react-img-mapper';
import { getAnalytics, logEvent } from "firebase/analytics";
import Spinner from 'react-bootstrap/Spinner';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App(props) {
  const {app} = props;

  const images = require.context('../public/assets', true);
  console.log(images);
  for (const image in images.keys) {
    console.log(image);
    new Image().src = image.fileName;
  }

  const [loading, setLoading] = React.useState(true);
  const [gateA, setGateA] = React.useState(false);
  const [gateB, setGateB] = React.useState(true);
  const [crystalC, setCrystalC] = React.useState(false);
  const [crystalD, setCrystalD] = React.useState(false);
  const [beacon, setBeacon] = React.useState(false);

  function getMap(gateA, gateB, crystalC, crystalD, beacon) {
    let gateAText = gateA ? "a" : "";
    let gateBText = gateB ? "b" : "";
    let crystalCText = crystalC ? "c" : "";
    let crystalDText = crystalD ? "d" : "";
    let beaconText = beacon ? "_beacon" : "";
    return "assets/map_" + gateAText + gateBText + crystalCText + crystalDText + beaconText + ".webp";
  }

  function handleClick(areaName) {
    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', { content_id: areaName, content_type: areaName});

    switch (areaName) {
      case "gateA":
        setGateA(!gateA)
        break;
      case "gateB":
        setGateB(!gateB)
        break;
      case "crystalC":
        setCrystalC(!crystalC)
        break;
      case "crystalD":
        setCrystalD(!crystalD)
        break;
      case "beacon":
        setBeacon(!beacon)
        break;
    }
  }


  return (
    <div className="App">
      <div className='img-fill'>
        <ImageMapper 
          src={getMap(gateA, gateB, crystalC, crystalD, beacon)} 
          responsive
          parentWidth={1500}
          map={{
            name: "my-map",
            areas: [
              { name: "gateA", shape: "poly", coords: [971, 395, 1000, 360, 1071, 360, 1071, 515, 971, 515], preFillColor: "#ffffff11" },
              { name: "gateB", shape: "rect", coords: [1190, 173, 1300, 323], preFillColor: "#ffffff11" },
              { name: "crystalC", shape: "rect", coords: [1339, 269, 1422, 341], preFillColor: "#ffffff11" },
              { name: "crystalD", shape: "rect", coords: [1303, 547, 1395, 626], preFillColor: "#ffffff11" },
              { name: "beacon", shape: "circle", coords: [894, 320, 97], preFillColor: "#ffffff11" },
            ]
          }}
          onClick={(area) => handleClick(area.name)}
          onImageClick={(area) => console.log(area)}
          onLoad={(_) => {console.log("loaded");setLoading(false)}}
          lineWidth={2}
          strokeColor='white'
        />
      </div>
      {loading ? <Spinner animation="border" /> : ""}
    </div>
  );
}

export default App;
